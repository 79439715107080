<template>
  <div class="container-fluid bg-light py-3 min-vh-75">
    <main class="container-xl">
      <h1>Servicios de telefonía IP y colaboración</h1>
      <div class="row gx-0 mt-5 mb-lg-5 my-0 justify-content-sm-center">
        <p class="fs-3 py-4">Simplifique la forma en que su organización se conecta, se compromete, crea relaciones y supera las expectativas cada vez mayores.</p>
        <p class="fs-5 py-4">El sistema de telefonía GoTo Connect para empresas es inteligente, fácil de usar y gestionar equipado con más de 100 funciones para todas sus necesidades de comunicación.</p>
      </div>
            <span>
                <router-link class="btn" to="/servicios/gotoconnect">
                    <img src="../assets/LMI_GoToConnect_Green_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotomeeting">
                    <img src="../assets/LMI_GoToMeeting_Orange_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotowebinar">
                    <img src="../assets/LMI_GoToWebinar_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinrescue">
                    <img src="../assets/LMI_Rescue_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinpro">
                    <img src="../assets/LMI_Pro.png" alt="/" height="80">
                </router-link>
            </span>
    </main>
  </div>
</template>
<script>
export default {
  name: 'LMIGeneral'
}
</script>
<style lang="css">
.min-vh-75{
  min-height: 75vh;
}
</style>